@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind typography;

:root {
    --color-primary-h: 330.4;
    --color-primary-s: 100%;
    --color-primary-l: 44.9%;
    --color-button-text: #ffffff;
}